import React from 'react'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'
import CardWrapper from '../CardWrapper/dynamic'
import GraphCounting from '../GraphCounting/dynamic'
import SkeletonAnimation from '../SkeletonAnimation/dynamic'
import PurposeOfNonMemberStyled from './styled'

import { withTranslation } from '../../../i18n'

const COLORS = ['#663c04', '#995a07', '#b8996f', '#ffead0']

export class PurposeOfNonMember extends React.Component {
  renderPieChart() {
    const { data, totalVisitor, loading } = this.props
    return (
      <GraphCounting
        className="purpose-nonmember-piechart"
        data={data}
        countingTotal={totalVisitor}
        countingText={pluralize(this.props.t('non-members'), totalVisitor)}
        colors={COLORS}
        loading={loading}
      />
    )
  }

  renderDataTest(purpose) {
    switch (purpose) {
      case 'Pick Up':
        return 'lbl-purpose-visitor-pick-up'
      case 'Delivery':
        return 'lbl-purpose-visitor-delivery'
      case 'Business':
        return 'lbl-purpose-visitor-business'
      case 'Others':
        return 'lbl-purpose-visitor-others'
      default:
        return 'lbl-purpose-visitor-other'
    }
  }

  replaceValue(val) {
    return val?.replace('.0', '')
  }

  renderData() {
    const { data } = this.props
    return (
      <div className="visit-wrapper">
        {data.map((purpose, index) => {
          let output = (
            <div className="purpose-wrapper" key={`${purpose}${index}`}>
              <div className="purpose-detail">
                <div className="purpose-color" style={{ backgroundColor: COLORS[index] }} />
                <div className="purpose-label" data-test={this.renderDataTest(purpose.name)}>
                  {this.props.t(purpose.name.toLowerCase())}
                </div>
                <div className="purpose-value" data-test={`${this.renderDataTest(purpose.name)}-value`}>
                  <div className="purpose-value-percentage">{`${this.replaceValue(purpose.percentage)}%`}</div>
                  <div className="purpose-value-count">{`${purpose.value}`}</div>
                </div>
              </div>
            </div>
          )
          if (this.props.loading) {
            output = <SkeletonAnimation id="purpose-visit-data-skeleton-loading" className="loading" key={`loading-${index}`} />
          }
          return output
        })}
      </div>
    )
  }

  renderPurposeOfVisitContent() {
    return (
      <div className="purpose-of-visit-content-wrapper">
        {this.renderPieChart()}
        {this.renderData()}
      </div>
    )
  }

  renderContent() {
    return (
      <CardWrapper title={this.props.t('purpose_of_non_member_visit')} id={'header-purpose-visitor'}>
        {this.renderPurposeOfVisitContent()}
      </CardWrapper>
    )
  }

  render() {
    return <PurposeOfNonMemberStyled className={this.props.className}>{this.renderContent()}</PurposeOfNonMemberStyled>
  }
}

PurposeOfNonMember.defaultProps = {
  loading: false,
}

PurposeOfNonMember.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  totalVisitor: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      percentage: PropTypes.string,
    }),
  ).isRequired,
  loading: PropTypes.bool,
}

export default withTranslation()(PurposeOfNonMember)
