import styled from 'styled-components'
import device from '../../utils/device-size'

export default styled.div`
  .purpose-of-visit-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .visit-wrapper {
      display: grid;
      grid-template-columns: auto auto;
      grid-template-rows: auto auto;
      grid-auto-flow: column;
      grid-row-gap: 8px;
      grid-column-gap: 24px;
      justify-content: center;
      align-items: center;
      padding-bottom: 33px;
      .loading {
        width: 108px;
        height: 30px;
        margin-bottom: 10px;
      }
      .purpose-wrapper {
        margin-bottom: 12px;
        position: relative;
        overflow: hidden;
        .purpose-detail {
          display: flex;
          flex-direction: row;
          align-items: center;
          .purpose-color {
            width: 12px;
            height: 12px;
            margin-right: 8px;
          }
          .purpose-label {
            font-size: 12px;
            color: #fff;
            width: 56px;
          }
          .purpose-value {
            display: flex;
            justify-content: end;
            font-size: 12px;
            color: #fff;
            width: 53px;
            position: relative;
            &:hover {
              .purpose-value-percentage {
                visibility: hidden;
                opacity: 0;
              }
              .purpose-value-count {
                text-align: end;
                visibility: visible;
                opacity: 1;
              }
            }
            .purpose-value-percentage {
              width: 100%;
              text-align: end;
              transition: 0.3s;
              visibility: visible;
              opacity: 1;
            }
            .purpose-value-count {
              position: absolute;
              left: 0;
              top: 0;
              text-align: end;
              visibility: hidden;
              opacity: 0;
              width: 100%;
              transition: 0.3s;
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: ${device.minSizeL}) and (max-width: ${device.maxSizeL}) {
    .purpose-of-visit-content-wrapper {
      .purpose-nonmember-piechart {
        margin-top: 10px;
      }
      .visit-wrapper {
        grid-row-gap: 8px;
        grid-column-gap: 15px;
        padding-bottom: 46px;
        .purpose-wrapper {
          .purpose-detail {
            .purpose-label {
              font-size: 12px;
              width: 56px;
            }
            .purpose-value {
              font-size: 12px;
              width: 34px;
            }
          }
        }
      }
    }
  }
  @media only screen and (min-width: ${device.minSizeM}) and (max-width: ${device.maxSizeM}) {
    .purpose-of-visit-content-wrapper {
      .purpose-nonmember-piechart {
        margin-top: 8px;
      }
      .visit-wrapper {
        padding-bottom: 42px;
        .purpose-wrapper {
          .purpose-detail {
            .purpose-label {
              font-size: 12px;
              width: 56px;
            }
            .purpose-value {
              font-size: 12px;
              width: 32px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: ${device.maxSizeS}) {
    .purpose-of-visit-content-wrapper {
      .visit-wrapper {
        .purpose-wrapper {
          .purpose-detail {
            .purpose-label {
              font-size: 12px;
              width: 56px;
            }
            .purpose-value {
              font-size: 12px;
              width: 32px;
            }
          }
        }
      }
    }
  }
`
